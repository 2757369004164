@import "../vars.scss";

.sectionHome {
}

.button {
  font-size: 32px;
  color: $navy;
  display: inline-block;
  padding: 15px 39px;
  font-style: italic;
  margin-top: 20px;
  margin-bottom: 20px;
}
