@import "vars.scss";

.root {
  background-color: white;
  text-align: center;
  color: black;
  padding: 30px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  max-width: 100px;
  filter: grayscale(100%) brightness(20%) contrast(200%);
}

.heading {
  text-transform: uppercase;
  text-decoration: none;
  color: inherit;
}
