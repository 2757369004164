@import "../vars.scss";

.root {
  cursor: pointer;
  text-decoration: none;
  background-color: white;
  color: #000;
  //text-transform: uppercase;
  display: inline-block;
  padding: 20px;
  border-radius: 32px;

  &:hover {
    background-color: rgb(240, 240, 240);
  }
}

.nopad {
  padding: 0;
}

.italic {
  font-style: italic;
  font-size: 1.5em;
}

.wide {
  padding-left: 40px;
  padding-right: 40px;
}

.outline {
  background-color: transparent;
  color: $salmon;
  border: 2px solid $salmon;
  border-radius: 999px;
  font-size: 20px;

  &:hover {
    background-color: $salmon;
    color: $navy;
  }
}
