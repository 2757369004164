.backButton {
  cursor: pointer;
  background-color: white;
  color: #000;
  text-transform: uppercase;
  display: inline-block;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 32px;
}
